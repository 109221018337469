/* Neo-200 - arabic */
@font-face {
  font-family: "Neo";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../../fonts/neo/NeoSansArabic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/neo/NeoSansArabic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Neo-300 - arabic */
@font-face {
  font-family: "Neo";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("../../fonts/neo/NeoSansArabicLight.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/neo/NeoSansArabicLight.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Neo-regular - arabic */
@font-face {
  font-family: "Neo";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../fonts/neo/../../fonts/neo/Neo Sans Arabic Regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/neo/Neo Sans Arabic Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Neo-500 - arabic */
@font-face {
  font-family: "Neo";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../../fonts/neo/NeoSansArabicMedium.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/neo/NeoSansArabicMedium.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Neo-700 - arabic */
@font-face {
  font-family: "Neo";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../fonts/neo/NeoSansArabicBold.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/neo/NeoSansArabicBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Neo-800 - arabic */
@font-face {
  font-family: "Neo";
  font-style: normal;
  font-weight: 800;
  src: local(""),
    url("../../fonts/neo/NeoSansArabicUltra.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/neo/NeoSansArabicUltra.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Neo-900 - arabic */
@font-face {
  font-family: "Neo";
  font-style: normal;
  font-weight: 900;
  src: local(""),
    url("../../fonts/neo/NeoSansArabicBlack.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/neo/NeoSansArabicBlack.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
