$font-family_1: Neo, "Sans", Helvetica, Arial, sans-serif;
$font-family_2: "Montserrat", sans-serif;

/*--------------------------------------------------------------
# Start Global Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# End Global Page
--------------------------------------------------------------*/
.rtl {
  direction: rtl !important;
  font-family: var(--font-ar) !important;
  overflow-x: hidden;

  .toggle-password {
    right: auto;
    left: 15px;
  }
  .side-content {
    .side-widget-content {
      left: auto !important;
      right: 0 !important;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
      display: none;
    }
  }
  .snack-bar-custom {
    @extend .ar-font;
    z-index: 3;
    text-align: right;
    .mat-mdc-simple-snack-bar {
      direction: rtl;
    }
  }
  .mat-mdc-snack-bar-container .mdc-snackbar__label {
    font-family: var(--font-ar);
  }
}
