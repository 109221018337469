.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background,
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: var(--main-color) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  background-color: #d9d9d9 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 2px !important;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: var(--main-color) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.mat-mdc-text-field-wrapper {
  background-color: var(--bg-input);
}
.matFile {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.mat-mdc-form-field,
.mat-mdc-floating-label,
.mdc-form-field,
.mat-stepper-vertical,
.mat-stepper-horizontal,
.mat-mdc-card-title,
.mdc-button,
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before,
.mat-mdc-option .mdc-list-item__primary-text{
  font-family: var(--font-en);
}
.rtl {
  .mat-mdc-form-field,
  .mat-mdc-floating-label,
  .mdc-form-field,
  .mat-stepper-vertical,
  .mat-stepper-horizontal,
  .mat-mdc-select,
  .mat-mdc-card-title,
  .mdc-button,
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
  .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before,
  .mat-mdc-option .mdc-list-item__primary-text,
  .mat-mdc-select-panel{
    font-family: var(--font-ar);
  }
}
.mat-mdc-floating-label {
  height: 50%;
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--main-color) !important;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--main-color) !important;
}
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle,
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--main-color) !important;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--main-color);
  --mdc-radio-selected-hover-icon-color: var(--main-color);
  --mdc-radio-selected-icon-color: var(--main-color);
  --mdc-radio-selected-pressed-icon-color: var(--main-color);
  --mat-mdc-radio-checked-ripple-color: var(--main-color);
}
.mat-horizontal-stepper-content {
  display: flex !important;
}
mat-error {
  margin-top: -1.7em;
}
.green-snackbar {
  --mdc-snackbar-container-color: rgb(65, 252, 134);
  &.mat-mdc-snack-bar-container .mdc-snackbar__label {
    color: black;
  }
}
.red-snackbar.mat-mdc-snack-bar-container{

  --mdc-snackbar-container-color: #F44336;

}

@media screen and (max-width: 676px) {
  .component-dialog {
    &.cdk-overlay-pane {
      width: 100%;
      height: 100%;
      min-width: 100% !important;
      min-height: 100% !important;
    }
  }
  .middle-dialog {
    &.cdk-overlay-pane {
      width: 90%;
      height: 50%;
      min-width: 90% !important;
      min-height: 50% !important;
    }
  }
}
@media screen and (max-width: 1200px) {
  .middle-dialog {
    &.cdk-overlay-pane {
      width: 60%;
      min-width: 60% !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  .middle-dialog {
    &.cdk-overlay-pane {
      width: 40%;
      min-width: 40% !important;
    }
  }
}
