/* montserrat-100 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-100.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-200.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: local(""),
    url("../../fonts/montserrat/montserrat-v25-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat/montserrat-v25-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
