.login-btn {
  @extend .base-btn;
  background-color: var(--alizarin-color);
  border-radius: 60px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #ffffff !important;
  padding: 10px 45px;
}
.base-btn {
  border: 0;
  &:hover:enabled {
    border: 1px solid var(--main-color);
    color: black;
  }
  &:disabled {
    background-color: var(--dim-gray-color);
    cursor: auto;
    opacity: 0.4;
  }
}
.trans-btn {
  @extend .base-btn;
  display: flex;
  padding: 9px 24px;
  gap: 10px;
  background: var(--white-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  color: #1191d0;
}
.logout-btn {
  @extend .base-btn;
  padding: 8px 8px;
  background: var(--white-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  direction: ltr;
  color: #1191d0;
}
.icon-btn {
  @extend .base-btn;
  padding: 8px 8px;
  background: var(--white-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  direction: ltr;
  color: #1191d0;

}
.icon-btn-round {
  @extend .base-btn;
  background: var(--white-color);
  border: 1px solid #d9d9d9;
  width: 40px;
  height: 40px;
  padding: 6px 0;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.green-btn {
  @extend .base-btn;
  padding: 9px 24px;
  background: var(--white-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  direction: ltr;
  color: var(--white-color);
  background-color: #009e19;
}
.main-btn {
  @extend .base-btn;
  display: flex;
  padding: 9px 24px;
  gap: 10px;
  position: absolute;
  background: #1191d0;
  border-radius: 5px;
  color: var(--white-color);
}
.sec-btn {
  @extend .main-btn;
  background-color: VAR(--sec-color);
}
.card-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 10px;
  & a{
    color: white;
    &:hover {
      color: var(--sec-color) !important;
      font-size: 20px;
    }

  }
}
.link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 10px;
  & a {
    color: var(--main-color);

    &:hover {
      color: var(--sec-color) !important;
      font-size: 20px;
    }
  }
}
.file-btn {
  margin-top: 3px;
  .mat-toolbar-single-row {
    height: auto !important;
    background: transparent;
  }
  #uploadFile {
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9;
    opacity: 0;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
}
.flex-grid {
  flex: 1 !important;
}
