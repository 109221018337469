/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./assets/scss/nav-bar";
@import "./assets/scss/table";
@import "./assets/scss/material";
@import "./assets/scss/controls";
@import "assets/scss/fonts/fonts";
@import "assets/scss/variables";
@import "assets/scss/rtl";
@import "assets/scss/mixin";
@import "assets/scss/animation";

html,
body {
  height: 100%;
}
body {
  height: 100%;
  font-family: var(--font-en);
  font-weight: 400;
  position: relative;
  text-align: start;
  font-size: 16px;
}
.main-color {
  color: var(--main-color);
}
.main-bg-color {
  background-color: var(--main-color);
}
.sec-color {
  color: var(--secColor);
}
.bg-light-gray {
  background-color: var(--light-gray);
}
.alizarin-color {
  color: var(--alizarin-color);
}
.nero-color3 {
  color: var(--nero-color3)f;
}
.alizarin-bg-color {
  color: var(--alizarin-color);
}
.home-page {
  //height: 100%;
  background: hsla(6, 94%, 94%, 1);

  background: linear-gradient(
    45deg,
    hsla(6, 94%, 94%, 1) 0%,
    hsla(208, 100%, 92%, 1) 100%
  );

  background: -moz-linear-gradient(
    45deg,
    hsla(6, 94%, 94%, 1) 0%,
    hsla(208, 100%, 92%, 1) 100%
  );

  background: -webkit-linear-gradient(
    45deg,
    hsla(6, 94%, 94%, 1) 0%,
    hsla(208, 100%, 92%, 1) 100%
  );
  min-height: -webkit-fill-available;

  //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FEE4E1", endColorstr="#D8EDFF", GradientType=1 );
}
.toggle-password {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.z-20 {
  z-index: 20;
}
@media screen and (min-width: 500px) {
  .grid-width {
    min-width: 280px !important;
  }
}
@media screen and (max-width: 1015px) {
  .flex-grid {
    flex: 1 1 auto !important;
  }
}
input[name="input-w"] {
  padding: 8px 10px;
  line-height: 28px;
}

.mat-horizontal-content-container,
.mat-horizontal-stepper-wrapper {
  height: 100%;
}
.mat-horizontal-stepper-content {
  height: 100%;
}
