@import "../../../assets/scss/fonts/montserrat-font";
@import "../../../assets/scss/fonts/neo_font";

//Fonts
.en-font {
  font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}
.ar-font {
  font-family: Neo, "Sans", Helvetica, Arial, sans-serif;
}
.thin {
  font-weight: 100;
}

.extralight {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.ultra {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

.fs-8 {
  font-size: 8px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-32 {
  font-size: 32px;
}
.fs-40 {
  font-size: 40px;
}
