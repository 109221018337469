:root {
  --main-color: #1191d0;
  --sec-color: #f6921d;

  --alizarin-color: #dd1e54;
  --white-color: #ffffff;
  --magnolia-color: #fbf9ff;
  --white-smoke-color: #f3f3f3;
  --whisper-color: #e9e9e9;
  --gainsboro-color: #dadada;
  --nobel-color: #a8a2a2;
  --light-gray: #fafafa;
  --dim-gray-color: #707070;
  --charcoal-color: #494949;
  --black-russian-color: #00000029;
  --nero-color: #272727;
  --nero-color2: #1f1f1f;
  --nero-color3: #222222;
  --green-color: #2ba200;
  --black-color: #000000;
  --bg-input: #f5f5f5;

  --font-en: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
  --font-ar: Neo, "Sans", Helvetica, Arial, sans-serif;
}
