

.user-profile.dropdown-menu {
  margin-top: 8px;
  padding: 0.5rem;
}

.user-profile.dropdown-menu a:hover {
  background-color: #f1f1f1;
  color: #333;
}

.user-profile.dropdown-menu a.dropdown-item {
  color: #212529;
  font-size: 14px;
}

.user-profile.dropdown-menu a.dropdown-item:hover {
  color: #212529;
}

.user-profile.dropdown-menu hr.dropdown-divider {
  margin: 0.25rem 0;
  border-color: #f1f1f1;
}
