.app-table {
  font-size: 14px;

  > :not(caption) > * > * {
    padding: 12px 18px;
    vertical-align: middle;
  }

  thead {
    background-color: var(--white-color);
    text-align: center;
    border-top: 0;
    th {
      padding: 18px;
    }
    line-height: 1;
  }

  tbody {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    border-top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    text-align: center;
    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
    .badge-status {
      color: var(--main-color);
      font-size: 14px;
      font-weight: normal;
      border-radius: 8px;
      &.new {
        background-color: var(--main-color);
      }
      &.ordered {
        background-color: rgba(235, 97, 0, 0.15);
      }
      &.in_progress {
        background-color: rgba(254, 203, 0, 0.15);
      }
      &.forwarded {
        background-color: rgba(156, 42, 160, 0.15);
      }
      &.solved {
        background-color: rgba(0, 138, 0, 0.15);
      }
      &.closed {
        background-color: rgba(126, 126, 126, 0.15);
      }
      &.other {
        background-color: rgba(94, 39, 80, 0.15);
      }
    }
  }
}

.app-table-card {
  border-color: var(--brx-gray-300);
  border-radius: 0;
  border: 0;
  font-size: 14px;
  line-height: normal;

  .card-header {
    background-color: #f4f4f4;
    border: 0;
    border-radius: 0;
    border-bottom: solid 2px var(--brx-primary);
    padding: 18px 20px;
  }

  .card-body {
    border-left: solid 1px var(--brx-gray-300);
    border-right: solid 1px var(--brx-gray-300);
    border-bottom: solid 1px var(--brx-gray-300);
    padding: 25px 20px;
    .list-group-item {
      border: 0;
      padding: 12px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 24px;
      }
      span {
        text-align: left;
        width: 50%;
      }
      .badge-status {
        color: var(--main-color);
        font-size: 14px;
        font-weight: normal;
        border-radius: 8px;
        width: unset;
        &.new {
          background-color: var(--main-color);
        }
        &.ordered {
          background-color: rgba(235, 97, 0, 0.15);
        }
        &.in_progress {
          background-color: rgba(254, 203, 0, 0.15);
        }
        &.forwarded {
          background-color: rgba(156, 42, 160, 0.15);
        }
        &.solved {
          background-color: rgba(0, 138, 0, 0.15);
        }
        &.closed {
          background-color: rgba(126, 126, 126, 0.15);
        }
        &.other {
          background-color: rgba(94, 39, 80, 0.15);
        }
      }
    }

    //.btn-incident-details {
    //  @include media-breakpoint-down(md) {
    //    width: 100%;
    //  }
    //}
  }
}

.goto-view-incident-tooltip {
  min-width: 263px;
}

.btn-view-incident {
  background-color: #f4f4f4;
  width: 35px;
  height: 35px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border: 0;
}

.loading-spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.full-page-spinner {
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  opacity: 0.8;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
